import React, {useState, useEffect} from "react";
import { getClubmeisterschaftenRemote } from "../utils/api";
import ReactMarkdown from 'react-markdown'
import ImageRenderer from "../components/ImageRenderer";
import remarkGfm  from 'remark-gfm'

export default function ClubmeisterschaftenView()  {


  const [cmItems,setCMItems] = useState([]);
    
    useEffect( () => {

        const fetchData = async () => {
          try {
            const data = await getClubmeisterschaftenRemote();                  
            setCMItems(data.data)
          } catch (e) {
            console.log(`Axios request failed: ${e}`);
          }
        }
        fetchData()
           
        }, []
      )
  
  return (
    <section className="section-page">
    <div className="container">
        <div className="section-title">
          <h2>Clubmeisterschaft</h2>
        </div>

        <div className="row">          
                {cmItems.map((item) => (                  
                  <div className="col" key={item.id}>
                    <a className="link-underline-primar" aria-current="page" type="button" href={`#anchor-${item.id}`}>{item.attributes.konkurrenz}</a>                  
                  </div>
                  ))
                }          
        </div>

        <div className="row row-cols-1">
                {cmItems.map((item) => (
                <div className="col" key={item.id}>
                  <div className="card mt-2" >
                    <div className="card-header" id={`anchor-${item.id}`}>{item.attributes.konkurrenz}</div>                      
                    <div className="card-body">                      
                      
                        <ReactMarkdown components={{ img: ImageRenderer }} remarkPlugins={[remarkGfm]}>                      
                          {item.attributes.inhalt}
                        </ReactMarkdown>
                      
                    </div>
                  </div>                      
                </div>
                ))
                }
        </div>

      </div>
      </section>
  );
};