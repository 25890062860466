import React from "react";
import SimpleContentSection from "./SimpleContentSection";
import Accordion from 'react-bootstrap/Accordion';

export default function MitgliedWerden()  {
   
    return (<section className="section-page">
              <div className="container">

              <div className="section-title">
                <h2>Mitglied werden</h2>
              </div>

              <SimpleContentSection path="/mitglied-werden"></SimpleContentSection>
                
               </div>
               </section>
            );
  
}